<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Vous êtes nouveau?</h4>
                            <h6 class="font-weight-light text-center">L'inscription est facile et se fait en quelques étapes</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                              <b-form-group id="pseudo" label="Nom d'utilisateur (pseudo)" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="pseudo"
                                  :state="$v.pseudo.$dirty ? !$v.pseudo.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- email -->
                              <b-form-group id="email" label="email" label-for="example-input-1">
                                <b-form-input
                                type="email"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="email"
                                  :state="$v.email.$dirty ? !$v.email.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- password -->
                              <b-form-group id="password" label="Mot de passe" label-for="example-input-1">
                                <b-form-input
                                type="password"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="password"
                                  :state="$v.password.$dirty ? !$v.password.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- confirmation password -->
                              <b-form-group id="confirmPassword" label="Confirmer le mot de passe" label-for="example-input-1">
                                <b-form-input
                                type="password"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="confirmPassword"
                                  :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Les mots de passe ne sont pas identiques
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- firstname -->
                              <b-form-group id="firstname" label="Nom" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="firstname"
                                  :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- lastname -->
                              <b-form-group id="lastname" label="Prénom" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="lastname"
                                  :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- tel -->
                              <b-form-group id="tel" label="Numéro téléphone" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="tel"
                                  :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <h4>Adresse</h4>
                               <!-- adresse Voie -->
                              <b-form-group id="WayAddress" label="Voie" label-for="wayAddress">
                                <b-form-input
                                  id="wayAddress"
                                  name="wayAddress"
                                  v-model="wayAddress"
                                  :state="$v.wayAddress.$dirty ? !$v.wayAddress.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>
                             
                              <!-- adresse ville -->
                              <b-form-group id="cityAddress" label="Ville" label-for="cityAddress">
                                <b-form-input
                                  id="cityAddress"
                                  name="cityAddress"
                                  v-model="cityAddress"
                                  :state="$v.cityAddress.$dirty ? !$v.cityAddress.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- Adresse code postal -->
                              <b-form-group id="postalCode" label="Code Postal" label-for="postalCode">
                                <b-form-input
                                  id="postalCode"
                                  name="postalCode"
                                  v-model="postalCode"
                                  :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- adresse département  -->
                              <b-form-group id="departmentAddress" label="Département" label-for="departmentAddress">
                                <b-form-input
                                  id="departmentAddress"
                                  name="departmentAddress"
                                  v-model="departmentAddress"
                                  :state="$v.departmentAddress.$dirty ? !$v.departmentAddress.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- adresse pays  -->
                              <b-form-group id="countryAddress" label="Pays" label-for="countryAddress">
                                <b-form-input
                                  id="countryAddress"
                                  name="countryAddress"
                                  v-model="countryAddress"
                                  :state="$v.countryAddress.$dirty ? !$v.countryAddress.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <div class="mt-3">
                                  <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white">S'inscrire</button>
                              </div>
                              <div class="my-2 d-flex justify-content-between align-items-center">
                                  <div class="form-check">
                                  <label class="form-check-label text-muted">
                                      <input type="checkbox" class="form-check-input">
                                      Rester connecté
                                      <i class="input-helper"></i>
                                  </label>
                                  </div>
                                  <a href="javascript:void(0);" class="auth-link text-black">Mot de passe oublié</a>
                              </div>
                              <div class="text-center mt-4 font-weight-light">
                                  Vous avez déjà un compte? <router-link to="/authentification" class="text-primary">se connecter</router-link>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios= require('axios').default
import { validationMixin } from 'vuelidate'
import { required,  sameAs, email} from 'vuelidate/lib/validators'
export default {
    name:'signin',
    mixins: [validationMixin],
    data () {
        return {
            ulidcompany:'',
            pseudo:'',
            email:'',
            password:'',
            confirmPassword:'',
            // roles:'',
            firstname:'',
            lastname:'',
            tel:'',
            wayAddress:'',
            cityAddress:'',
            departmentAddress:'',
            countryAddress:'',
            postalCode:'',
            datePublished:'',
            dateModified:''
        }
    },
    validations: {
        // ulidcompany: {
        //     required
        // },
        pseudo: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required
        },
        confirmPassword: {
          sameAs: sameAs('password')
        },
        firstname: {
            required
        },
        lastname: {
            required
        },
        tel: {
            required
        },
        datePublished: {
            required
        },
        wayAddress:{
          required
        },
        cityAddress: {
          required
        },
        departmentAddress: {
          required
        },
        countryAddress: {
          required
        },
        postalCode: {
          required
        }
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
        if (this.$v.$anyError) {
          return
        }

          axios.post('myinfos',
            {
                // ulidcompany: '01F1Z1PT53H19FAWCV0PG480RP',
                // apitoken:'',
                pseudo: this.pseudo,
                email: this.email,
                password: this.password,
                // roles: ['ROLE_USER_DASHBOARD'],
                firstname: this.firstname,
                lastname: this.lastname,
                tel: this.tel,
                address: {
                  Voie:this.wayAddress,
                  Ville:this.countryAddress,
                  Département:this.departmentAddress,
                  Pays:this.countryAddress,
                  PostalCode:this.postalCode
                },
                // datepublished: '2021-03-24 10:07:00',
                // datemodified: '2021-03-24 10:07:00'
            })
            .then(res=> {
              console.log(res),
              this.$router.push({
                name:'signinConfirmation'
              })
            })
            .catch(err=>{
              alert('Votre inscription comporte une erreur')
              console.log(err)})
        }
    },
    mounted () {
      this.datePublished = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
      this.dateModified = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
    }
}
</script>